import React from "react"
import styled from "@emotion/styled"
import { Button } from "@material-ui/core"
import { GREY } from "../Colors"

const OutlinedButton = props => {
  return (
    <AccentedButton
      greyed={props.disabled ? "true" : "false"}
      variant="outlined"
      color="primary"
      href={props.href}
    >
      {props.children}
    </AccentedButton>
  )
}

// STYLES
const AccentedButton = styled(Button)`
  ${({ greyed }) =>
    greyed === "true" &&
    `
    color: ${GREY};
    border-color: ${GREY};
    pointer-events: none;
  `}
`

export default OutlinedButton
