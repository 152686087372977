import React from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import { Container } from "@material-ui/core"
import Image from "gatsby-image"
import useSiteLogo from "../hooks/use-sitelogo"
import OutlinedButton from "./OutlinedButton"
import { GREY, PRIMARY_GREEN, WHITE } from "../Colors"
import { Link } from "gatsby"

const Footer = () => {
  const { largerLogo } = useSiteLogo()

  return (
    <FooterContainer>
      <FooterMain>
        <Image fixed={largerLogo.fixed} />
        {/*<OutlinedButton
          style={css`
            font-size: 1.3rem;
          `}
          disabled={true}
          variant="outlined"
          color="secondary"
        >
          Let´s Chat
        </OutlinedButton>*/}
      </FooterMain>
      {/*<FooterBottom>
        <FooterLink activeClassName={"active"} to={"/"}>
          home
        </FooterLink>
        <FooterLink
          activeClassName={"active"}
          to={"/case-studies"}
          disabled={true}
        >
          case studies
        </FooterLink>
        <FooterLink activeClassName={"active"} to={"/blog"}>
          blog
        </FooterLink>
        <FooterLink activeClassName={"active"} to={"/about"}>
          about
        </FooterLink>
        <FooterLink activeClassName={"active"} to={"/contact"}>
          contact
        </FooterLink>
      </FooterBottom>*/}
      <Copyright>
        © {new Date().getFullYear()} Hreem IT AB, Klövervägen 11, 14567
        Stockholm
      </Copyright>
    </FooterContainer>
  )
}

const FooterContainer = styled(Container)`
  margin-top: 5rem;
`

const FooterMain = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 0.5rem;
`

const FooterBottom = styled.div`
  margin-top: 1rem;
  display: flex;
  justify-content: center;
`

const FooterLink = styled(Link)`
  text-transform: uppercase;
  text-decoration: none;
  color: ${({ disabled }) => (disabled ? GREY + "85" : WHITE)};
  margin-right: 2.8rem;
  font-weight: 600;

  &.active {
    color: ${PRIMARY_GREEN};
  }

  &:last-of-type {
    margin-right: 0;
  }

  ${({ disabled }) =>
    disabled &&
    `
      pointer-events: none;
  `}
`

const Copyright = styled.p`
  font-size: 1rem;
  color: ${GREY}85;
  font-family: monospace;
  text-align: center;
  margin-bottom: 3rem;
`

export default Footer
