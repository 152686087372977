import React from "react"
import Image from "gatsby-image"
import styled from "@emotion/styled"
import { Grid, Container } from "@material-ui/core"
import OutlinedButton from "./OutlinedButton"
import HeaderNavigationLink from "./HeaderNavigationLink"
import useSiteLogo from "../hooks/use-sitelogo"
import { Link } from "gatsby"
import useMediaQuery from "@material-ui/core/useMediaQuery"

const Header = () => {
  const showButton = useMediaQuery("(min-width:1139px)")
  const centerHeaderItems = useMediaQuery("(max-width:1009px)")
  const { smallLogo, largerLogo } = useSiteLogo()

  return (
    <HeaderContainer>
      <Grid container direction="row" justify="center" alignItems="center">
        <Grid item>
          <LogoSegment>
            <Link to={"/"}>
              <Image
                fixed={centerHeaderItems ? largerLogo.fixed : smallLogo.fixed}
              />
            </Link>
          </LogoSegment>
        </Grid>
        {/*<Grid item xs={8}>
          <NavigatorSegment centerchildren={centerHeaderItems}>
            <HeaderNavigationLink to="/" linkName="Home" />
            <HeaderNavigationLink
              disabled={true}
              to="/case/"
              partiallyActive={true}
              linkName="Case Studies"
              linkTooltipTitle="Coming soon"
              linkTooltipDesc="Case studies is a space where we test and post architectures or technical solutions, and discuss the benefits and/or drawbacks of these patterns."
            />
            <HeaderNavigationLink
              to="/blog/"
              partiallyActive={true}
              linkName="Blog"
            />
            <HeaderNavigationLink to="/about/" linkName="About" />
            <HeaderNavigationLink to="/contact/" linkName="Contact" />
            {showButton && (
              <OutlinedButton
                disabled={true}
                variant="outlined"
                color="secondary"
              >
                Let´s Chat
              </OutlinedButton>
            )}
          </NavigatorSegment>
        </Grid>*/}
      </Grid>
    </HeaderContainer>
  )
}

// STYLES
const HeaderContainer = styled(Container)`
  margin-top: 50px;
`

const LogoSegment = styled.div`
  img {
    float: left;
  }
  h1 {
    float: right;
    margin-top: 12px;
    margin-left: 15px;
    font-family: "Raleway", sans-serif;
  }
`
const NavigatorSegment = styled.div`
  text-align: ${({ centerchildren }) => (centerchildren ? "center" : "right")};
`

export default Header
