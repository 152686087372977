import React from "react"
import { withStyles } from "@material-ui/core/styles"
import Tooltip from "@material-ui/core/Tooltip"
import Typography from "@material-ui/core/Typography"
import PropTypes from "prop-types"
import { PRIMARY_GREEN, SECONDARY_BG_TRANSPARENT } from "../Colors"

const StyledTooltip = props => {
  return (
    <HtmlTooltip
      title={
        <React.Fragment>
          <Typography color="primary">
            <h3>{props.tooltipTitle}</h3>
          </Typography>
          {props.tooltipDesc}
        </React.Fragment>
      }
    >
      {props.children}
    </HtmlTooltip>
  )
}

StyledTooltip.propTypes = {
  tooltipTitle: PropTypes.string.isRequired,
  tooltipDesc: PropTypes.string,
}

// STYLES
const HtmlTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: SECONDARY_BG_TRANSPARENT,
    color: "white",
    maxWidth: 300,
    fontSize: theme.typography.pxToRem(14),
    border: `1.5px dotted ${PRIMARY_GREEN}`,
  },
}))(Tooltip)

export default StyledTooltip
