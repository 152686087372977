import { graphql, useStaticQuery } from "gatsby"

const useSiteLogo = () => {
  const data = useStaticQuery(graphql`
    {
      small: contentfulLogo(title: { eq: "logo-fixed" }) {
        logo: logoImage {
          fixed(width: 320) {
            ...GatsbyContentfulFixed_tracedSVG
          }
        }
      }
      larger: contentfulLogo(title: { eq: "logo-fixed" }) {
        logo: logoImage {
          fixed(width: 400) {
            ...GatsbyContentfulFixed_tracedSVG
          }
        }
      }
    }
  `)

  return { smallLogo: data.small.logo, largerLogo: data.larger.logo }
}

export default useSiteLogo
