import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import { PRIMARY_GREEN } from "../Colors"
import StyledTooltip from "./StyledTooltip"

const HeaderNavigationLink = props => {
  const renderLink = () => {
    return !props.disabled ? (
      <NavigationLink
        disabled={props.disabled}
        activeStyle={{
          color: PRIMARY_GREEN,
          textDecoration: "overline",
        }}
        to={props.to}
        partiallyActive={props.partiallyActive}
      >
        {props.linkName}
      </NavigationLink>
    ) : (
      <DisabledNavigation>{props.linkName}</DisabledNavigation>
    )
  }

  return props.linkTooltipTitle !== undefined ? (
    <StyledTooltip
      tooltipTitle={props.linkTooltipTitle}
      tooltipDesc={props.linkTooltipDesc}
    >
      {renderLink()}
    </StyledTooltip>
  ) : (
    renderLink()
  )
}

HeaderNavigationLink.propTypes = {
  to: PropTypes.string,
  linkName: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  linkTooltipTitle: PropTypes.string,
  linkTooltipDesc: PropTypes.string,
}

// STYLES
const NavigationLink = styled(Link)`
  font-size: 20px;
  text-transform: uppercase;
  padding-right: 2em;
  color: white;
  text-decoration: none;

  &:last-of-type {
    padding-right: 1rem;
  }
`

const DisabledNavigation = styled.span`
  font-size: 20px;
  text-transform: uppercase;
  padding-right: 2em;
  color: #808080;
  text-decoration: none;
`

export default HeaderNavigationLink
