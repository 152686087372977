import React from "react"
import Header from "../components/Header"
import { Global, css } from "@emotion/core"
import {
  PRIMARY_BG,
  PRIMARY_GREEN,
  SECONDARY_BG_TRANSPARENT,
  SECONDARY_BG,
  DARKER_GREEN,
} from "../Colors"
import "./layout.css"
import { Helmet } from "react-helmet"
import useSiteMetadata from "../hooks/use-sitemetadata"
import Footer from "../components/Footer"

const Layout = ({ children }) => {
  const { title, description } = useSiteMetadata()
  return (
    <>
      <Global
        styles={css`
          body {
            font-family: "Baloo Tamma 2", cursive;
            color: #ffffff;
            margin: 0;
            background-color: #22273b;
            background-image: radial-gradient(
              rgb(255 255 255 / 20%) 1px,
              transparent 0
            );
            background-size: 40px 40px;
            background-position: -19px -19px;
          }

          .secondary-segment {
            background-color: ${PRIMARY_BG};
            -webkit-box-shadow: inset 0px 3px 12px -6px rgba(0, 0, 0, 0.7);
            -moz-box-shadow: inset 0px 3px 12px -6px rgba(0, 0, 0, 0.7);
            box-shadow: inset 0px 3px 12px -6px rgba(0, 0, 0, 0.7);
            min-height: 45vw;
          }

          .secondary-segment-light {
            padding-top: 1rem;
            background-color: ${SECONDARY_BG};
            padding-bottom: 1rem;
          }

          .secondary-segment-dark {
            padding-top: 1rem;
            background-color: ${PRIMARY_BG};
            padding-bottom: 1rem;
          }

          footer {
            padding-top: 1rem;
            background-color: ${SECONDARY_BG_TRANSPARENT};
            padding-bottom: 1rem;
          }

          h5 {
            color: ${DARKER_GREEN};
            text-transform: uppercase;
          }

          h3 {
            color: ${PRIMARY_GREEN};
            text-transform: uppercase;
          }
          h1 {
            font-family: "PT Serif", serif;
            font-size: 36px;
          }

          body h1,
          h2,
          h3,
          h4,
          h5,
          h6 {
            font-family: "Nanum Gothic", sans-serif;
            + * {
              margin-top: 0.3rem;
              padding-bottom: 1rem;
            }
          }
        `}
      />
      <Helmet>
        <html lang={"en"} />
        <title>{title}</title>
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="favicon/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="favicon/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="favicon/favicon-16x16.png"
        />
        <link rel="manifest" href="favicon/site.webmanifest" />
        <link
          rel="mask-icon"
          href="favicon/safari-pinned-tab.svg"
          color="#5bbad5"
        />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#ffffff" />
        <meta name={"description"} content={description} />
      </Helmet>
      <header>
        <Header />
      </header>
      <main>{children}</main>
      <footer>
        <Footer />
      </footer>
    </>
  )
}

export default Layout
